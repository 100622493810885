<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          รายการพนักงาน
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Header -->
          <b-row>
            <b-col md="3" sm="2" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">แสดง</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" v-on:change="onPageSelect" style="width: 60px"/>
                <label class="d-inline-block text-sm-right ml-50">รายการ</label>
              </b-form-group>
            </b-col>
            <b-col md="5" sm="6" class="my-1">
              <b-row>
                <div class="btn-group btn-group-sm mx-auto" role="group">
                  <b-button variant="outline-secondary" class="btn" disabled>role: </b-button>
                  <b-button class="btn btn-danger" @click="sorted='status:0';fetch()">ผู้ดูแลระบบสูงสุด</b-button>
                  <b-button class="btn btn-warning" @click="sorted='status:1';fetch()">ผู้ดูแลระบบ</b-button>
                  <b-button class="btn btn-success" @click="sorted='status:2';fetch()">พนักงาน</b-button>
                  <b-button class="btn btn-secondary" @click="sorted='status:3';fetch()">ผู้ใช้ทั่วไป</b-button>
                  <b-button class="btn bg-gradient-primary" @click="sorted='';fetch()">ทั้งหมด</b-button>
                </div>
              </b-row>
            </b-col>
            <b-col md="4" sm="4" class="my-1">
              <b-form-group label="ค้นหา :" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" v-on:change="onPageSelect" type="search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> ล้าง </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- End Table Header -->
          <!-- Table Body -->
          <b-row>
            <b-col cols="12">
              <b-table hover responsive :fields="fields" :items="items" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                <template #empty="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #emptyfiltered="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>

                <template #cell(username)="data">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :src="data.item.pictureStatus"
                        :text="avatarText(data.item.username)"
                      />
                    </template>
                    <span
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ data.item.username }}
                    </span>
                    <small class="text-muted">@{{ data.item.prefix }}</small>
                  </b-media>
                </template>

                <template #cell(role)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :icon="resolveUserRoleIcon(data.item.role)"
                      size="18"
                      class="mr-50"
                      :class="`text-${resolveUserRoleVariant(data.item.role)}`"
                    />
                    <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
                  </div>
                </template>

                <template #cell(verify)="data">
                  <b-badge
                    pill
                    :variant="`light-${data.value == true ? 'success' : 'warning'}`"
                    class="text-capitalize"
                  >
                    {{ data.value == true ? 'อนุมัติแล้ว' : 'รออนุมัติ' }}
                  </b-badge>
                </template>
                <template #cell(manage)="data">
                  <div class="btn-group">
                    <b-dropdown variant="link" toggle-class="bg-gradient-success waves-effect waves-light">
                      <template #button-content>
                        <span><feather-icon icon="EditIcon" class="font-medium-1 mr-1" /></span>จัดการ
                      </template>
                      <b-dropdown-item  @click="modal_user" v-b-modal.modal-user :data-id="data.item.id" :data-username="data.item.username" :data-prefix="data.item.prefix" :data-role="data.item.role" :data-verify="data.item.verify"><span><feather-icon icon="SearchIcon" class="text-info mr-1" /></span>แก้ไข</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <template v-if="data.item.verify == true">
                        <b-dropdown-item @click="verify_auth(false, data.item)"><span><feather-icon icon="SlashIcon" class="text-secondary mr-1" /></span>ไม่อนุมัติยูส</b-dropdown-item>
                      </template>
                      <template v-else>
                        <b-dropdown-item @click="verify_auth(true, data.item)"><span><feather-icon icon="CheckIcon" class="text-success mr-1" /></span>อนุมัติยูส</b-dropdown-item>
                      </template>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="delete_auth(data.item)"><span><feather-icon icon="TrashIcon" class="text-danger mr-1" /></span>ลบออก</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- End Table Body -->
          <!-- Table Footer -->
          <b-row>
            <b-col sm="12" md="5">
              <div role="status" aria-live="polite">แสดง {{ func.show_page(perPage, currentPage) }} ถึง {{ func.show_page_to(perPage, currentPage, recordsTotal) }} จาก {{func.toNum(totalRows)}} รายการ (ค้นหา จากทั้งหมด {{perPage}} รายการ)</div>
            </b-col>
            <b-col sm="12" md="7">
              <b-pagination
                v-on:change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="end"
                size="sm"
                class="my-0"
                :first-number="true"
                :last-number="true"
              >
                <template #prev-text>‹ ย้อนกลับ</template>
                <template #next-text>ถัดไป ›</template>
              </b-pagination>
            </b-col>
          </b-row>
          <!-- End Table Footer -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <b-modal
      id="modal-user"
      centered
      title="แก้ไขข้อมูล"
      ok-only
      ok-title="ตกลง"
      ok-variant=" bg-gradient-primary waves-effect waves-light"
      @ok="modal_user_ok"
    >
      <b-row>
        <b-col cols="md-6">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Username" v-model="username" disabled/>
          </b-input-group>
        </b-col>
        <b-col cols="md-6">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="LockIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Prefix" v-model="prefix" disabled/>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="md-6">
          <b-form-select v-model="role" :options="option_role"/>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="md-12">
          <b-form-checkbox
            v-model="verify"
            checked="true"
            class="custom-control-success text-nowrap"
            name="check-button"
            switch
            inline
          >
            ล็อคยูส (อนุมัติ/ไม่อนุมัติ)
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider, BMedia, BAvatar, BBadge, BModal, BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import func from '@core/comp-functions/misc/func'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton, BMedia, BAvatar, BBadge,
    BFormSelect, BModal, BFormCheckbox,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
    flatPickr,
  },
  setup() {
    return {
      avatarText,
    }
  },
  data() {
    return {
      func: func,
      BASE_URL: process.env.BASE_URL,
      date_start_report: '',
      date_end_report: '',
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      sorted: '',
      filter: '',
      filterOn: [],
      fields: [
        { key: 'username', label: 'ยูสเซอร์', class: 'text-left text-nowrap' },
        { key: 'role', label: 'ตำแหน่ง', class: 'text-left text-nowrap' },
        { key: 'verify', label: 'สถานะ', class: 'text-center text-nowrap' },
        { key: 'manage', label: 'จัดการข้อมูล', class: 'text-center' },
      ],
      items: [],
      username: '',
      prefix: '',
      verify: '',
      role: '',
      id: '',
      option_role: [
        { text: 'แอดมินสูงสุด', value: 'admin' },
        { text: 'ผู้ดูแลระบบ', value: 'system' },
        { text: 'พนักงาน', value: 'employee' },
        { text: 'ผู้ใช้ทั่วไป', value: 'user' },
      ],
      t_fetch: 0,
    }
  },
  mounted() {
    if (this.$route.query.ref) {
      this.filter = 'ref:' + this.$route.query.ref;
    }
    this.totalRows = this.items.length
    this.fetch()
    this.t_fetch = setInterval(() => {
      if (this.currentPage != 1) return;
      this.fetch()
    }, 1000 * 60)
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    resolveUserRoleIcon(role) {
      if (role == "user") return 'UserIcon'
      if (role == "admin") return 'ServerIcon'
      if (role == "system") return 'SettingsIcon'
      return 'DatabaseIcon'
    },
    resolveUserRoleVariant(role) {
      if (role == "user") return 'primary'
      if (role == "admin") return 'danger'
      if (role == "system") return 'warning'
      return 'success'
    },
    onFiltered(filteredItems) {
      if (this.filter != '' && this.filter.startsWith('status:')) {
        this.filter = ''
      }
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      let start = this.perPage * (this.currentPage - 1)
      this.fetch(start)
    },
    onPageChange(page) {
      let start = this.perPage * (page - 1)
      this.fetch(start)
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        willOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    warn(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
    },
    fetch(start=0) {
      let url = `setting/auth_list_page?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.sorted != '' ? this.sorted : this.filter}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data) })
    },
    modal_user(e) {
      if (!e.target.dataset) return;
      let { id, username, prefix, role, verify } = e.target.dataset;
      this.id = id;
      this.username = username;
      this.prefix = prefix;
      this.verify = verify;
      this.role = role;
    },
    modal_user_ok() {
      let url = `setting/auth/${this.id}`;
      let data = {
        role: this.role,
        verify: this.verify
      }
      this.$http.post(url, data).then(res => {
        if (res.data != null && res.data.result != null) this.fetch();
      })
    },
    verify_auth(v, data) {
      let { id, username } = data;
      let text = 'อนุมัติให้ ' + username + ' หรือไม่?'
      if (v == false) text = 'ยกเลิกอนุมัติให้ ' + username + ' หรือไม่?'
      let url = '/setting/auth/' + id
      this.$swal({
        title: 'แจ้งเตือน',
        html: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
		    cancelButtonColor: 'red',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger mr-1',
        },
        buttonsStyling: false,
		    reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.loading()
          this.$http.post(url, { verify: v }).then(res => {
            this.$swal.close()
            if (res.data.result) {
              this.fetch()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ทำรายการสำเร็จ',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: res.data.message,
                },
              })
            } else {
              if(res.data.error && res.data.message != null) {
                this.warn(res.data.message)
              } else {
                this.warn('ทำรายการไม่สำเร็จ')
              }
              this.fetch()
            }
          }).catch((error) => {
            this.$swal.close()
            this.warn('ทำรายการไม่สำเร็จ error:'+error)
          })
        }
      })
    },
    delete_auth(data) {
      let { id, username } = data;
      let text = 'คุณต้องการลบ ' + username + '  ออกจากระบบหรือไม่?'
      let url = '/setting/auth/' + id
      this.$swal({
        title: 'แจ้งเตือน',
        html: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
		    cancelButtonColor: 'red',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger mr-1',
        },
        buttonsStyling: false,
		    reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.loading()
          this.$http.post(url, { delete: true }).then(res => {
            this.$swal.close()
            if (res.data.result) {
              this.fetch()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ทำรายการสำเร็จ',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: res.data.message,
                },
              })
            } else {
              if(res.data.error && res.data.message != null) {
                this.warn(res.data.message)
              } else {
                this.warn('ทำรายการไม่สำเร็จ')
              }
              this.fetch()
            }
          }).catch((error) => {
            this.$swal.close()
            this.warn('ทำรายการไม่สำเร็จ error:'+error)
          })
        }
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.t_fetch)
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
